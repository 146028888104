import logo from './logo.svg';
import './App.css';
import Cdc from './component/cdc';
import Notifications from './component/notification';

function App() {
  return (
    <div className="App">
      <Cdc />
    </div>
  );
}

export default App;
